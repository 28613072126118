$color-one: rgb(0, 61, 115);
$color-two: rgb(0, 37, 70);
$color-three: rgb(135, 135, 135);
$color-four: rgb(75, 75, 74);

$color-correct: rgb(37, 177, 9);
$color-wrong: rgb(255, 5, 0);
$color-not-selected: rgb(143, 148, 144);
$color-orange: rgb(242, 113, 28);

$button-hover-color: rgb(223, 224, 225);
