@import '~antd/dist/antd.css';
@import '~semantic-ui-css/semantic.min.css';

@import '_colors';
@import '_layout';

@import '_headers';

@import '_question';

@import '_feedback';

@import '_profile';

@import '_print';

.click {
  cursor: pointer !important;
}
