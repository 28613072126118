.question {
  margin-top: 20px;

  table {
    border-collapse: collapse;

    tr {
      &:last-child {
        td {
          border-bottom: 0;
        }
      }
      th,
      td {
        margin: 0;
        padding: 5px;
        border-bottom: 1px solid rgb(196, 196, 196);

        &:not(:last-child) {
          border-right: 1px solid rgb(196, 196, 196);
        }
      }
    }
  }
}

#root {
  .ui.button {
    /*	background-color: $color-one;
	color: #fff; 

	&:hover,
	&.active,
	&.active:hover {
		background-color: $color-two;
		color: #fff;
	}*/

    &.green {
      background-color: $color-correct;
    }
    &.red {
      background-color: $color-wrong;
    }
    &.grey {
      background-color: $color-not-selected;
    }

    &.green,
    &.red,
    &.grey {
      &:hover {
        cursor: default;
      }
    }
  }

  .pristine {
    button,
    button:hover,
    button:active,
    button:focus,
    button.active,
    button.active:active {
      background-color: $button-hover-color;
    }
  }

  .svar-korrekt {
    color: $color-correct;
  }
  .svar-forkert {
    color: $color-wrong;
  }
  .svar-examMode {
    color: $color-orange;
  }
}

.comment img {
  display: block;
  max-width: 80%;
  max-height: 50vh;
  margin: 5px auto;
  height: auto;
  width: auto;
}
