#root {
  .ui.list.analysis {
    .item {
      .correct,
      .wrong,
      .mixed {
        font-weight: bold;
      }
      .correct {
        color: green;
      }

      .wrong {
        color: red;
      }

      .mixed {
        color: rgb(218, 110, 27);
      }
    }
  }
}
