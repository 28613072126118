@media print {
  .noprint * {
    display: none !important;
  }

  .onprint {
    display: block !important;
  }
}

.onprint {
  display: none;
  color: black;
}
